import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const About = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  return (
    <section className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <Image
            className="title-image"
            src={require("./../../assets/images/TITLE_ABOUT_US.png")}
          />
          <p
            className="section-header center-content container-xs pm-0"
            style={{ color: "white" }}
          >
            At Paramo Games, we work in a collaborative style environment where
            we give everyone on the team the chance to be part of the creative
            process.
          </p>
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <h3 className="mt-0 mb-8" style={{ fontFamily: "Gilbert" }}>
                    MISSION
                  </h3>
                </div>
                <p className="text-sm mb-0 border" style={{ color: "white" }}>
                  We want to develop games that are fun and entertaining for
                  everyone. We want that people feels identified with our games
                  and can enjoy them as much as we do.
                </p>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <h3 className="mt-0 mb-8" style={{ fontFamily: "Gilbert" }}>
                    VISSION
                  </h3>
                </div>
                <p className="text-sm mb-0 border" style={{ color: "white" }}>
                  We want to continue making great games for many years to come
                  and to become a leading company in the games industry and a
                  referent in our country.
                </p>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <h3 className="mt-0 mb-8" style={{ fontFamily: "Gilbert" }}>
                    VALUES
                  </h3>
                </div>
                <p className="text-sm mb-0 border" style={{ color: "white" }}>
                  <span style={{ color: "#2185cd" }}>Innovation</span> and doing
                  new things every day.
                  <br />
                  <span style={{ color: "#2185cd" }}>Curiosity</span> always
                  exploring and learning.
                  <br />
                  <span style={{ color: "#2185cd" }}>Passion</span> for all that
                  what we do.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className={innerClasses}>
          <Image
            src={require("./../../assets/images/TITLE_OUR_TEAM.png")}
            className="title-image"
          />

          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/TEAM_ART.png")}
                      width={100}
                      height={100}
                    />
                  </div>

                  <h3 className="mt-0 mb-8" style={{ fontFamily: "Gilbert" }}>
                    ART
                  </h3>
                </div>
                <p className="text-sm mb-0" style={{ color: "white" }}>
                  UI / UX <br />
                  Concept Art
                  <br />
                  Key/Capsule Art, Icons
                  <br />
                  3D Character, Props, & Environments
                  <br />
                  3D & 2D Animation
                </p>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/TEAM_PROGRAMMING.png")}
                      width={100}
                      height={100}
                    />
                  </div>
                  <h3 className="mt-0 mb-8" style={{ fontFamily: "Gilbert" }}>
                    ENGINEERING
                  </h3>
                </div>
                <p className="text-sm mb-0" style={{ color: "white" }}>
                  PC, Mobile, Web <br />
                  AR/VR Development <br />
                  Visualization & Simulation <br />
                  Online Networking <br />
                  Backend Services
                </p>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/TEAM_GAME_DESIGN.png")}
                      width={100}
                      height={100}
                    />
                  </div>
                  <h3 className="mt-0 mb-8" style={{ fontFamily: "Gilbert" }}>
                    GAME DESIGN
                  </h3>
                </div>
                <p className="text-sm mb-0" style={{ color: "white" }}>
                  Design Consulting <br />
                  Narrative Design <br />
                  Level Design <br />
                  Environment Design & Implementation
                </p>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/TEAM_VFX.png")}
                      width={100}
                      height={100}
                    />
                  </div>
                  <h3 className="mt-0 mb-8" style={{ fontFamily: "Gilbert" }}>
                    TECH ART
                  </h3>
                </div>
                <p className="text-sm mb-0" style={{ color: "white" }}>
                  VFX <br />
                  Particle Effects <br />
                  Shader Development <br />
                  Rigging
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className={innerClasses}>
          <p
            className="section-header center-content container-xs pm-0"
            style={{ color: "white" }}
          >
            We are happy to work with these technologies.
          </p>
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/SOFTWARE_1.png")}
                      width={400}
                      height={50}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/SOFTWARE_2.png")}
                      width={100}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/SOFTWARE_3.png")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/SOFTWARE_4.png")}
                      width={80}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

About.propTypes = propTypes;
About.defaultProps = defaultProps;

export default About;
