import { useTranslation } from "react-i18next";
import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const Games = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const { t } = useTranslation();

  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  return (
    <section
      // {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <Image
            src={require("./../../assets/images/TITLE_IN_DEVELOPMENT.png")}
            className="title-image"
          />
          <p
            className="section-header center-content container-xs pm-0"
            style={{ color: "white" }}
          >
            {t("games.paragraph")}
          </p>
          <div className={splitClasses}>
            <img className="game-divider" />
            <div></div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/GAME_ICON_LLAMALANDIA.png")}
                  width={100}
                  height={100}
                />
                <h3 className="mt-0 mb-12">LLAMALANDIA</h3>
                <div className="m-0-paragraph">
                  <p className="m-0-paragraph-games">
                    Llamalandia is a cozy adventure game about finding your
                    family and making a special bond with each llama you meet.
                    Build your llama sanctuary to protect your family, raise the
                    baby llamas so you can take them on your adventures, and
                    invite other cute animals to live with you in this magical
                    place.{" "}
                  </p>

                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://store.steampowered.com/app/2470990/Llamalandia/"
                    >
                      <img
                        style={{ padding: "5% 10% 5% 10%" }}
                        src={require("./../../assets/images/LINK_STEAM.png")}
                        alt=""
                      />
                    </a>

                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://youtu.be/SD7aoKMh4cs"
                    >
                      <img
                        style={{ padding: "5% 10% 5% 10%" }}
                        src={require("./../../assets/images/LINK_YOUTUBE.png")}
                        alt=""
                      />
                    </a>
                  </div>

                  <p
                    className="m-0-paragraph-games"
                    style={{ fontSize: "16px" }}
                  >
                    <span style={{ color: "#2185cd" }}>GENRE:</span>{" "}
                    COZY / ADVENTURE <br />
                    <span style={{ color: "#2185cd" }}>PLATFORM:</span>{" "}
                    PC / CONSOLES
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  className="has-shadow"
                  src={require("./../../assets/images/GAME_COVER_LLAMALANDIA.jpg")}
                  alt="Llamalandia"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <img className="game-divider" />
            <div></div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/GAME_ICON_SPOOKY_HIDE.png")}
                  width={100}
                  height={100}
                />
                <h3 className="mt-0 mb-12">SPOOKY HIDE</h3>
                <div className="m-0-paragraph">
                  <p className="m-0-paragraph-games">
                    Spooky Hide is a casual hide and seek multiplayer game,
                    where you can pick between a ghost hunter or a ghost. Find
                    all ghosts before the time runs out or escape from the
                    hunters by hiding on different object of the map
                  </p>

                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://paramo-games.itch.io/spooky-hide"
                    >
                      <img
                        style={{ padding: "5% 10% 5% 10%" }}
                        src={require("./../../assets/images/LINK_ITCH.png")}
                        alt=""
                        width={150}
                      />
                    </a>

                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://youtu.be/ZvzFpeS2lwo"
                    >
                      <img
                        style={{ padding: "5% 10% 5% 10%" }}
                        src={require("./../../assets/images/LINK_YOUTUBE.png")}
                        alt=""
                        width={150}
                      />
                    </a>
                  </div>
                  <p
                    className="m-0-paragraph-games"
                    style={{ fontSize: "16px" }}
                  >
                    <span style={{ color: "#2185cd" }}>GENRE:</span> ONLINE PARTY GAME{" "}
                    <br />
                    <span style={{ color: "#2185cd" }}>PLATFORM:</span>{" "}
                    PC / CONSOLES
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  className="has-shadow"
                  src={require("./../../assets/images/GAME_COVER_SPOOKY_HIDE.jpg")}
                  alt="Spooky Hide"
                  width={528}
                  height={396}
                />
              </div>
            </div>
            <img className="game-divider" />
            <div></div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/GAME_ICON_COLORLESS.png")}
                  width={100}
                  height={100}
                />
                <h3 className="mt-0 mb-12">COLORLESS</h3>

                <div className="m-0-paragraph">
                  <p className="m-0-paragraph-games">
                    Colorless is an action adventure game, where you will
                    explore a lifeless world that needs you to be restored. Use
                    the power of color to your adventage, and unlock different
                    powers that will helpt you in your journey.
                  </p>
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://paramo-games.itch.io/colorless"
                    >
                      <img
                        style={{ padding: "5% 10% 5% 10%" }}
                        src={require("./../../assets/images/LINK_ITCH.png")}
                        alt=""
                        width={150}
                      />
                    </a>

                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://youtu.be/Vq8iLwJNvEA"
                    >
                      <img
                        style={{ padding: "5% 10% 5% 10%" }}
                        src={require("./../../assets/images/LINK_YOUTUBE.png")}
                        alt=""
                        width={150}
                      />
                    </a>
                  </div>

                  <p
                    className="m-0-paragraph-games"
                    style={{ fontSize: "16px" }}
                  >
                    <span style={{ color: "#2185cd" }}>GENRE:</span>{" "}
                    ACTION / ADVENTURE <br />
                    <span style={{ color: "#2185cd" }}>PLATFORM:</span> PC
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  className="has-shadow"
                  src={require("./../../assets/images/GAME_COVER_COLORLESS.jpg")}
                  alt="Colorless"
                  width={528}
                  height={396}
                />
              </div>
            </div>
            <img className="game-divider" />
          </div>
        </div>
      </div>
    </section>
  );
};

Games.propTypes = propTypes;
Games.defaultProps = defaultProps;

export default Games;
