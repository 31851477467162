import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
const userLang = navigator.language || navigator.userLanguage;
const defaultLocale = userLang.substring(0,2);
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: defaultLocale,
    fallbackLng: 'en',
  });
export default i18n;