import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <div style={{ justifyItems: "center" }}>
              <Image
                src={require("./../../assets/images/logo_central.png")}
                className="home-logo"
              />

              <img className="creating-paths" />
            </div>

            <div
              className="tiles-wrap center-content"
              style={{ marginTop: "3%" }}
            >
              <img className="ornament-one"/>
              <p
                className="m-0 mb-32 reveal-from-bottom presentation"
                data-reveal-delay="400">
                We are an independent game studio from Bolivia. <br />
                Our team is talented, passionate and experienced, looking
                forward to make more amazing games!
              </p>
              <img className="ornament-two"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
