import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const Values = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <Image src={require('./../../assets/images/TITLE_OUR_SERVICES.png')} className="title-image"/>
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/SERVICE_PROTOTYPE.png')}
                      width={80}
                      height={80} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h3 className="mt-0 mb-8" style={{fontFamily: 'Gilbert'}}>
                    PROTOTYPING
                  </h3>
                  <p className="m-0 text-sm" style={{color:'white'}}>
                    We can quickly transform your paper designs and ideas into something playable. Our team has a lot of experience doing prototypes and iterating them according to the client needs.                  
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/SERVICE_CO_DEV.png')}
                      width={80}
                      height={80} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                <h3 className="mt-0 mb-8" style={{fontFamily: 'Gilbert'}}>
                  CO-DEVELOPMENT
                  </h3>
                  <p className="m-0 text-sm" style={{color:'white'}}>
                    We can develop your project as a co-development production. Our team will adapt to the different processes that you are using, or provide them in case you need them.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/SERVICE_FULL_CYCLE.png')}
                      width={80}
                      height={80} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h3 className="mt-0 mb-8" style={{fontFamily: 'Gilbert'}}>
                  FULL-CYCLE DEVELOPMENT
                  </h3>
                  <p className="m-0 text-sm" style={{color:'white'}}>
                  We can build your full game from scratch. Our team can take care of the full development of your project saving you time and money in the process.
                  </p>
                </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/SERVICE_SUPPORT.png')}
                      width={80}
                      height={80} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h3 className="mt-0 mb-8" style={{fontFamily: 'Gilbert'}}>
                  POST-RELEASE SUPPORT
                  </h3>
                  <p className="m-0 text-sm" style={{color:'white'}}>
                    Have you finished your project and want to move on to the next one? Our team can assist you with future content updates, bug fixing, and any other needs that you may have.

                  </p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>
  );
}

Values.propTypes = propTypes;
Values.defaultProps = defaultProps;

export default Values;