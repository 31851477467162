import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../elements/Image';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <div className="footer-top space-between text-xxs" style={{justifyContent: 'center'}}>
            <Image className="footer-image"
            src={require('../../assets/images/ALL_RIGHTS_COMPLEMENTED.png')}/>       
            </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop" style={{justifyContent: 'center', color:'white'}}>
            {/* <div className="footer-copyright">Made by Paramo Games. All right reserved</div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;